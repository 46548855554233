<template>
  <div>
    <base-period-select
      v-model="searchDates"
      class="justify-start ml-2"
      :bootstrap="BOOTSTRAP_DASHBOARD"
      :select-periodos-names="selectPeriodosNames"
      :mensal="true"
    />
    <base-loading
      v-if="!ready"
      base-class="fill-height ma-0 flex-column"
    />
    <v-container
      v-else
      fluid
    >
      <v-row>
        <v-col
          v-if="dashboard.grafico_cpp"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            :options="optAtualAnterior(pluginsCbt)"
            :data="dashboard.grafico_cpp.data || []"
            type="Bar"
            color="primary"
            title="Acompanhamento da CBT"
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: 50 x (1000 UFC/mL)"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemCbt"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_cpp_inter"
            :options="optInter"
            :data="dashboard.grafico_cpp_inter.data || []"
            type="Bar"
            color="primary"
            title="(%) Fazendas por intervalo de CBT"
            icon="mdi-barn"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-hand-pointing-up"
            sub-icon-color-right="#558b2f"
            sub-text-right="Clique sobre as barras para ver os valores"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemCbt"
            class="intervaloCbtCcs"
          />
        </v-col>
        <v-col
          v-if="dashboard.grafico_ccs"
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            :options="optAtualAnterior(pluginsCcs)"
            :data="dashboard.grafico_ccs.data || []"
            type="Bar"
            color="primary"
            title="Acompanhamento da CCS"
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: 150 x (1000 CS/mL)"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemCcs"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_ccs_inter"
            :options="optInter"
            :data="dashboard.grafico_ccs_inter.data || []"
            type="Bar"
            color="primary"
            title="(%) Fazendas por intervalo de CCS"
            icon="mdi-barn"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-hand-pointing-up"
            sub-icon-color-right="#558b2f"
            sub-text-right="Clique sobre as barras para ver os valores"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemCcs"
            class="intervaloCbtCcs"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_gord"
            :data="dashboard?.grafico_gord?.data || []"
            :options="optAtualAnterior(pluginsGord)"
            type="Bar"
            color="primary"
            title="Acompanhamento de Gordura"
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: Gordura 4,1 %"
            sub-text-color-right="#558b2f"
            elevation="4"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_gord_inter"
            :options="optInter"
            :data="dashboard.grafico_gord_inter.data || []"
            type="Bar"
            color="primary"
            title="(%) Fazendas por intervalo de Gordura"
            icon="mdi-barn"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-hand-pointing-up"
            sub-icon-color-right="#558b2f"
            sub-text-right="Clique sobre as barras para ver os valores"
            sub-text-color-right="#558b2f"
            elevation="4"
            class="intervaloGordProt"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_prot"
            :data="dashboard?.grafico_prot?.data || []"
            :options="optAtualAnterior(pluginsProt)"
            type="Bar"
            color="primary"
            title="Acompanhamento de Proteína"
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: Proteína 3,6 %"
            sub-text-color-right="#558b2f"
            elevation="4"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_prot_inter"
            :options="optInter"
            :data="dashboard.grafico_prot_inter.data || []"
            type="Bar"
            color="primary"
            title="(%) Fazendas por intervalo de Proteína"
            icon="mdi-barn"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-hand-pointing-up"
            sub-icon-color-right="#558b2f"
            sub-text-right="Clique sobre as barras para ver os valores"
            sub-text-color-right="#558b2f"
            elevation="4"
            class="intervaloGordProt"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_lact"
            :options="optAtualAnterior(pluginsLact)"
            :data="dashboard?.grafico_lact?.data || []"
            type="Bar"
            color="primary"
            title="Acompanhamento da Lactose"
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: 4,3 %"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemLact"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_lact_inter"
            :options="optInter"
            :data="dashboard.grafico_lact_inter.data || []"
            type="Bar"
            color="primary"
            title="(%) Fazendas por intervalo de Lactose"
            icon="mdi-barn"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-hand-pointing-up"
            sub-icon-color-right="#558b2f"
            sub-text-right="Clique sobre as barras para ver os valores"
            sub-text-color-right="#558b2f"
            elevation="4"
            class="intervaloLactose"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafioc_esd"
            :options="optAtualAnterior(pluginsEsd)"
            :data="dashboard?.grafioc_esd?.data || []"
            type="Bar"
            color="primary"
            title="Acompanhamento do Extrato Seco Desengordurado"
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: 8,5 %"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemEsd"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_est"
            :options="optAtualAnterior(pluginsEst)"
            :data="dashboard?.grafico_est?.data || []"
            type="Bar"
            color="primary"
            title="Acompanhamento do Extrato Seco Total"
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: 12 %"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemEst"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_nul"
            :options="optNul()"
            :data="dashboard?.grafico_nul?.data || []"
            type="Bar"
            color="primary"
            title="Acompanhamento do Nitrogênio Ureico"
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: 8,5 a 16 (mg/dl)"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemNul"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="pl-6 pr-6"
        >
          <material-chart-card
            v-if="dashboard.grafico_cmp"
            :options="optAtualAnterior(pluginsCmp)"
            :data="dashboard?.grafico_cmp?.data || []"
            type="Bar"
            color="primary"
            title="Acompanhamento do Caseinomacropeptídeo "
            icon="mdi-flask-outline"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            sub-icon-right="mdi-flask"
            sub-icon-color-right="#558b2f"
            sub-text-right="Referência: 30 (mg/L)"
            sub-text-color-right="#558b2f"
            elevation="4"
            :sub-click-right="mensagemCmp"
          />
        </v-col>
        <v-col
          cols="12"
          class="pl-6 pr-6"
        >
          <material-card
            color="primary"
            :title="mapaQualidade"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            elevation="4"
          >
            <v-card
              style="margin: 0px 0px 5px 0px"
              outlined
              class="pb-3 pt-3"
            >
              <v-row
                justify="center"
                class="px-5"
                style="height: 33px;"
              >
                <v-radio-group
                  v-model="tipo"
                  row
                >
                  <v-radio
                    value="0"
                    @click="trocaFaixa"
                  >
                    <template v-slot:label>
                      <div>
                        <strong>Análises de CBT</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="1"
                    @click="trocaFaixa"
                  >
                    <template v-slot:label>
                      <div><strong>Análises de CCS</strong></div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-row>
              <v-row
                v-if="tipo == '0'"
                justify="center"
                class="px-5 pl-1 pr-1"
              >
                <v-radio-group
                  key="keyRadio"
                  v-model="faixa"
                  row
                >
                  <v-radio
                    value="0"
                    @click="keyMap = keyMap + 1"
                  >
                    <template v-slot:label>
                      <div>
                        <strong>{{ '< 50' }}</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="1"
                    @click="keyMap = keyMap + 1"
                  >
                    <template v-slot:label>
                      <div><strong>50 a 100</strong></div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="2"
                    @click="keyMap = keyMap + 1"
                  >
                    <template v-slot:label>
                      <div><strong>100 a 300</strong></div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="3"
                    @click="keyMap = keyMap + 1"
                  >
                    <template v-slot:label>
                      <div><strong>> 300</strong></div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-row>
              <v-row
                v-if="tipo == '1'"
                justify="center"
                class="px-5 pl-1 pr-1"
              >
                <v-radio-group
                  key="keyRadio"
                  v-model="faixa"
                  row
                >
                  <v-radio
                    value="0"
                    @click="keyMap = keyMap + 1"
                  >
                    <template v-slot:label>
                      <div>
                        <strong>{{ '< 150' }}</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="1"
                    @click="keyMap = keyMap + 1"
                  >
                    <template v-slot:label>
                      <div><strong>150 a 300</strong></div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="2"
                    @click="keyMap = keyMap + 1"
                  >
                    <template v-slot:label>
                      <div><strong>300 a 500</strong></div>
                    </template>
                  </v-radio>
                  <v-radio
                    value="3"
                    @click="keyMap = keyMap + 1"
                  >
                    <template v-slot:label>
                      <div><strong>> 500</strong></div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-row>
            </v-card>
            <v-card
              style="margin: 0px;"
              outlined
            >
              <div
                v-if="tipo == '0' && faixa == '0'"
              >
                <gmap-map
                  v-if="dashboard && dashboard.map_cbt && dashboard.map_cbt.CPP_50"
                  key="keyMap"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 655px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 660px;' : 'width: 100%;height: 865px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.map_cbt.CPP_50"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon0"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.sigla }}: </span><span style="font-weight: bold; color: rgb(0,184, 0)">{{ element.analise }} x (1000 UFC/mL)</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
              <div
                v-if="tipo == '0' && faixa == '1'"
              >
                <gmap-map
                  key="keyMap"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 698px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 660px;' : 'width: 100%;height: 900px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.map_cbt.CPP_50_100"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon1"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.sigla }}: </span><span style="font-weight: bold; color: rgb(34, 84, 255)">{{ element.analise }} x (1000 UFC/mL)</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
              <div
                v-if="tipo == '0' && faixa == '2'"
              >
                <gmap-map
                  v-if="dashboard && dashboard.map_cbt && dashboard.map_cbt.CPP_100_300"
                  key="keyMap"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 698px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 660px;' : 'width: 100%;height: 900px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.map_cbt.CPP_100_300"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon2"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.sigla }}: </span><span style="font-weight: bold; color: rgb(255, 192, 0)">{{ element.analise }} x (1000 UFC/mL)</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
              <div
                v-if="tipo == '0' && faixa == '3'"
              >
                <gmap-map
                  v-if="dashboard && dashboard.map_cbt && dashboard.map_cbt.CPP_300"
                  key="keyMap"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 698px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 660px;' : 'width: 100%;height: 900px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.map_cbt.CPP_300"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon3"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.sigla }}: </span><span style="font-weight: bold; color: rgb(255, 0, 0)">{{ element.analise }} x (1000 UFC/mL)</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
              <div
                v-if="tipo == '1' && faixa == '0'"
              >
                <gmap-map
                  v-if="dashboard && dashboard.map_ccs && dashboard.map_ccs.CCS_150"
                  key="keyMap"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 698px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 660px;' : 'width: 100%;height: 900px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.map_ccs.CCS_150"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon0"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.sigla }}: </span><span style="font-weight: bold; color: rgb(0,184, 0)">{{ element.analise }} x (1000 CS/mL)</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
              <div
                v-if="tipo == '1' && faixa == '1'"
              >
                <gmap-map
                  v-if="dashboard && dashboard.map_ccs && dashboard.map_ccs.CCS_150_300"
                  key="keyMap"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 698px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 660px;' : 'width: 100%;height: 900px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.map_ccs.CCS_150_300"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon1"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.sigla }}: </span><span style="font-weight: bold; color: rgb(34, 84, 255)">{{ element.analise }} x (1000 CS/mL)</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
              <div
                v-if="tipo == '1' && faixa == '2'"
              >
                <gmap-map
                  v-if="dashboard && dashboard.map_ccs && dashboard.map_ccs.CCS_300_500"
                  key="keyMap"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 698px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 660px;' : 'width: 100%;height: 900px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.map_ccs.CCS_300_500"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon2"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.sigla }}: </span><span style="font-weight: bold; color: rgb(255, 192, 0)">{{ element.analise }} x (1000 CS/mL)</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
              <div
                v-if="tipo == '1' && faixa == '3'"
              >
                <gmap-map
                  v-if="dashboard && dashboard.map_ccs && dashboard.map_ccs.CCS_500"
                  key="keyMap"
                  :center="center"
                  :zoom="8"
                  :style="( this.$vuetify.breakpoint.lg ? 'width: 100%;height: 698px;' : this.$vuetify.breakpoint.lx ? 'width: 100%;height: 660px;' : 'width: 100%;height: 900px;' )"
                >
                  <gmap-marker
                    v-for="(element, index) in dashboard.map_ccs.CCS_500"
                    :key="`t-${index}`"
                    :position="element.position"
                    :clickable="true"
                    :icon="icon3"
                    @click="center = element.position"
                    @mouseover="showByIndex = index"
                    @mouseout="showByIndex = null"
                  >
                    <gmap-info-window :opened="showByIndex === index">
                      <p class="mb-1">
                        <span style="font-weight: bold;">Produtor: </span><span>{{ element.produtor }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">Fazenda: </span><span>{{ element.fazenda }}</span>
                      </p>
                      <p class="mb-1">
                        <span style="font-weight: bold;">{{ element.sigla }}: </span><span style="font-weight: bold; color: rgb(255, 0, 0)">{{ element.analise }} x (1000 CS/mL)</span>
                      </p>
                    </gmap-info-window>
                  </gmap-marker>
                </gmap-map>
              </div>
            </v-card>
            <template v-slot:actions>
              <p class="mb-0">
                <span style="font-weight: bold;">* Produtores sem dados de localização, não são visualizados.</span>
              </p>
            </template>
          </material-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import MaterialChartCard from '../../components/base/MaterialChartCard'
  import MaterialCard from '../../components/base/MaterialCard'
  import { BOOTSTRAP_DASHBOARD } from '@/store/modules/qualidades'
  import Tooltip from 'chartist-plugin-tooltips-updated'
  import Swal from 'sweetalert2'
  import Legend from 'chartist-plugin-legend'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'DashboardQualidade',
    components: {
      MaterialChartCard,
      MaterialCard,
    },
    data () {
      return {
        date: new Date(),
        meses: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        tipo: '0',
        faixa: '0',
        keyMap: 0,
        keyRadio: 0,
        center: { lat: 0, lng: 0 },
        showByIndex: 0,
        icon0: {
          url: require('../../assets/img_map/cauboy1.png'),
          size: { width: 23, height: 23, f: 'px', b: 'px' },
          scaledSize: { width: 23, height: 23, f: 'px', b: 'px' },
        },
        icon1: {
          url: require('../../assets/img_map/cauboy2.png'),
          size: { width: 23, height: 23, f: 'px', b: 'px' },
          scaledSize: { width: 23, height: 23, f: 'px', b: 'px' },
        },
        icon2: {
          url: require('../../assets/img_map/cauboy3.png'),
          size: { width: 23, height: 23, f: 'px', b: 'px' },
          scaledSize: { width: 23, height: 23, f: 'px', b: 'px' },
        },
        icon3: {
          url: require('../../assets/img_map/cauboy4.png'),
          size: { width: 23, height: 23, f: 'px', b: 'px' },
          scaledSize: { width: 23, height: 23, f: 'px', b: 'px' },
        },

        optInter: {
          referencevalue: 300,
          low: 0,
          high: 100,
          showArea: false,
          stackBars: true,
          axisX: {
            offset: 20,
            showGrid: false,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
            labelInterpolationFnc: function (value) {
              return value + ' %'
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          plugins: [
            Legend(),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return currencyFormatter(value, 2) + '%' + '<br />' + meta + ' Fazendas' },
            }),
          ],
          chartPadding: {
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },

        searchDates: {
          name: 'qualidade',
          label: '',
          selected: 'Trimestre',
          from: null,
          to: null,
        },
        selectPeriodosNames: [
          'Mês',
          'Trimestre',
          'Semestre',
          'Ano',
          'Selecionar período',
        ],
        pluginsCbt: {
          textToltip: ' (x 1000 UFC/mL)',
          valueLinha: { value: 50, max: 50 },
          casasDecimais: 0,
          paramLabel: ' k',
        },

        pluginsCcs: {
          textToltip: ' (x 1000 CS/mL)',
          valueLinha: { value: 150, max: 150 },
          casasDecimais: 0,
          paramLabel: ' k',
        },
        pluginsEst: {
          textToltip: ' (%)',
          valueLinha: { value: 12, max: 12 },
          casasDecimais: 2,
          low: 6,
          paramLabel: ' %',
        },
        pluginsCmp: {
          textToltip: ' (mg/L)',
          valueLinha: { value: 30, max: 30 },
          casasDecimais: 0,
          paramLabel: ' k',
        },
        pluginsLact: {
          textToltip: ' (%)',
          valueLinha: { value: 4.3, max: 4.3 },
          casasDecimais: 2,
          low: 2,
          high: 6,
          paramLabel: ' %',
        },
        pluginsEsd: {
          textToltip: ' (%)',
          valueLinha: { value: 8.5, max: 8.5 },
          casasDecimais: 2,
          low: 6,
          paramLabel: ' %',
        },
        pluginsGord: {
          textToltip: ' (%)',
          valueLinha: { value: 4.1, max: 4.1 },
          casasDecimais: 2,
          low: 2,
          high: 5,
          paramLabel: ' %',
        },
        pluginsProt: {
          textToltip: ' (%)',
          valueLinha: { value: 3.6, max: 3.6 },
          casasDecimais: 2,
          low: 2,
          high: 5,
          paramLabel: ' %',
        },
      }
    },
    computed: {
      ...mapState({ user: state => state.user }),
      ...mapState('qualidades', ['ready', 'dashboard']),
      ...mapGetters(['hasPermission']),

      mapaQualidade () {
        let firstDate = this.moment(this.searchDates.from || this.moment().subtract(1, 'year').format('YYYY-MM-DD'))
        let lastDate = this.moment(this.searchDates.to) || this.moment().format('YYYY-MM-DD')

        firstDate = `${this.meses[firstDate.month()]}/${firstDate.year()}`
        lastDate = `${this.meses[lastDate.month()]}/${lastDate.year()}`

        return `Mapa da Qualidade de Leite - ${firstDate} a ${lastDate}`
      },

      labelGraficoComparativo () {
        switch (this.searchDates.label) {
          case 2:
            return ['Mês anterior', 'Mês atual']
          case 3:
            return ['Trimestre anterior', 'Trimestre atual']
          case 4:
            return ['Semestre anterior', 'Semestre atual']
          case 5:
            return ['Ano anterior', 'Ano atual']
          case 6:
            return ['Período anterior', 'Período atual']
          default:
            return ['teste', 'teste']
        }
      },
      labelGrafico () {
        switch (this.searchDates.label) {
          case 2:
            return 'Mês atual'
          case 3:
            return 'Trimestre atual'
          case 4:
            return 'Semestre atual'
          case 5:
            return 'Ano atual'
          case 6:
            return 'Período ' + this.searchDates.selected
          default:
            return this.searchDates.label
        }
      },
    },
    mounted () {
      this.geolocate()
    },
    methods: {
      ...mapActions('qualidades', [BOOTSTRAP_DASHBOARD]),
      mensagemCbt () {
        Swal.fire({
          title: 'Contagem Bacteriana Total - CBT',
          text: 'Também chamada de CPP, nada mais é do que um indicador de contaminação bacteriana e reflete a higienização no processo de ordenha, limpeza dos equipamentos e resfriamento do leite. Os atuais limites vigentes determinados pela Instrução Normativa 76 e 77 são de 300 mil unidades formadoras de colônias por mililitro ( 300 mil UFC/mL ).',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemCcs () {
        Swal.fire({
          title: 'Contagem de células somáticas - CCS',
          text: 'A CCS, consiste em uma importante ferramenta que indica a saúde da glândula mamária de vacas leiteiras. Vacas sadias e com boa saúde da glândula mamária possuem valores de CCS de até 200.000 células/mL de leite. Valores superiores indicam que há algum desequilíbrio na glândula mamária, possivelmente devido a ocorrência de mastite. Os Atuais limites vigentes determinados pela Instrução Normativa 76 e 77 são de 500 células por mililitro ( 500 mil cs/mL ).',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemNul () {
        Swal.fire({
          title: 'Nitrogênio ureico no leite - NUL',
          text: 'O nitrogênio ureico no leite (NUL) é um indicativo da adequação ou excesso de amônia ruminal em relação à energia disponível para o crescimento microbiano no rúmen. Alta quantidade de proteína disponível no rúmen (degradável/solúvel) em relação à quantidade de carboidratos disponíveis resulta em altos níveis de nitrogênio ureico no leite.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemCmp () {
        Swal.fire({
          title: 'Caseinomacropeptídeo - CMP',
          text: 'Caseinomacropeptídeo ou CMP, é um fragmento da k-caseína, que é liberado na coagulação enzimática do leite na produção de queijos. O índice de CMP, é a principal análise que determina se houve uma possível adulteração do leite, através da adição de agua ou soro.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemLact () {
        Swal.fire({
          title: 'Lactose - LACT',
          text: 'A análise mensura a concentração da lactose, um açúcar, presente no leite. A concentração de lactose no leite é de aproximadamente 5% (4,7 a 5,2%). É um dos elementos mais estáveis sujeito a menor variação.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemEst () {
        Swal.fire({
          title: 'Extrato seco total - EST',
          text: 'O extrato seco total (EST) ou sólidos totais é o somatório da concentração de todos os componentes do leite exceto a água.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      mensagemEsd () {
        Swal.fire({
          title: 'Extrato Seco Desengordurado - ESD',
          text: 'O Extrato Seco Desengordurado(ESD) compreendem-se todos os elementos do leite, menos a água e a gordura. Os componentes do leite permanecem em equilíbrio, de modo que a relação entre eles é muito estável.',
          showConfirmButton: true,
          confirmButtonColor: '#109010',
          confirmButtonText: 'Voltar',
          focusConfirm: false,
        })
      },
      trocaFaixa () {
        this.faixa = '0'
        this.keyRadio = this.keyRadio + 1
      },
      geolocate: function () {
        const location = { lat: 0, lng: 0 }
        function errorCallback (erro) {
          const { code } = erro
          switch (code) {
            case GeolocationPositionError.TIMEOUT:
              window.navigator.geolocation.getCurrentPosition(
                successCallback,
                errorCallback)
              break
            case GeolocationPositionError.PERMISSION_DENIED:
              Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'Foi negado a Plataforma acesso as informações de localização do usuário!',
              })
              break
            case GeolocationPositionError.POSITION_UNAVAILABLE:
              Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'As informações de localização do usuário são inválidas!',
              })
              break
          }
        }

        function successCallback (position) {
          location.lat = position.coords.latitude
          location.lng = position.coords.longitude
        }

        if (navigator.geolocation) {
          try {
            navigator.geolocation.getCurrentPosition(
              successCallback,
              errorCallback,
              { enableHighAccuracy: true, timeout: 5000, maximumAge: 600000 },
            )
          } catch {
            navigator.geolocation.getCurrentPosition(
              successCallback,
              errorCallback)
          }
        }
        this.center = location
      },
      linha (options) {
        var defaultOptions = {
          class: 'ct-target-line',
          value: null,
          linhaFnc: this.$chartist.noop,
          max: 0,
        }

        options = this.$chartist.extend({}, defaultOptions, options)

        return function ctTargetLine (chart) {
          function projectY (chartRect, bounds, value) {
            // nao mexer neste calculo ele calcula ja a diferenca entre o valor maximo e minimo
            return chartRect.y1 - ((chartRect.height() / (bounds.max - bounds.min)) * (value - bounds.min))
          }

          chart.on('draw', function (data) {
            if (data.value) {
              options.max = options.max < data.value.y ? data.value.y : options.max
            }
          })

          if (options.value <= options.max) {
            chart.on('created', function (context) {
              var targetLineY = projectY(context.chartRect, context.bounds, options.value)

              if (targetLineY <= context.chartRect.height() && targetLineY > 0 && options.value <= context.bounds.max) {
                context.svg.elem('line', {
                  x1: context.chartRect.x1,
                  x2: context.chartRect.x2,
                  y1: targetLineY,
                  y2: targetLineY,
                }, options.class)
              }
            })
          }
        }
      },
      pontos (options) {
        var defaultOptions = {
          labelClass: 'ct-label',
          labelOffset: {
            x: 0,
            y: -10,
          },
          textAnchor: 'middle',
          align: 'center',
          labelInterpolationFnc: this.$chartist.noop,
        }

        var labelPositionCalculation = {
          point: function (data) {
            return {
              x: data.x,
              y: data.y,
            }
          },
          bar: {
            left: function (data) {
              return {
                x: data.x1,
                y: data.y1,
              }
            },
            top: function (data) {
              return {
                x: data.x1,
                y: data.y2,
              }
            },
            center: function (data) {
              return {
                x: data.x1 + (data.x2 - data.x1) / 2,
                y: data.y1,
              }
            },
            right: function (data) {
              return {
                x: data.x2,
                y: data.y1,
              }
            },
          },
        }

        options = this.$chartist.extend({}, defaultOptions, options)

        function addLabel (position, data) {
          var meta = data.meta
          var value = data.value.x !== undefined && data.value.y
            ? (data.value.x + ', ' + data.value.y)
            : data.value.y || data.value.x

          value = value === undefined ? 0 : value

          data.group.elem('text', {
            x: position.x + options.labelOffset.x,
            y: position.y + options.labelOffset.y,
            style: 'text-anchor: ' + options.textAnchor,
          }, options.labelClass).text(options.labelInterpolationFnc(meta, value))
        }

        return function ctPointLabels (chart) {
          chart.on('draw', function (data) {
            var positonCalculator = (labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align]) || labelPositionCalculation[data.type]
            if (positonCalculator) {
              addLabel(positonCalculator(data), data)
            }
          })
        }
      },
      optAtualAnterior ({ textToltip, valueLinha, casasDecimais, paramLabel = '', low = 0, high = null }) {
        const opt = {
          low: low,
          showArea: false,
          seriesBarDistance: 17,
          axisX: {
            offset: 20,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
            labelInterpolationFnc: function (value) {
              return value + paramLabel
            },
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          plugins: [
            this.$chartist.plugins.legend({ legendNames: this.labelGraficoComparativo }),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) {
                return meta + '<br />' + parseFloat(value).toLocaleString('pt-BR', { maximumFractionDigits: casasDecimais }) + textToltip
              },
            }),
            this.linha(valueLinha),
          ],
          chartPadding: {
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
        if (high) {
          opt.high = high
        }
        return opt
      },
      optNul () {
        return {
          low: 4,
          showArea: false,
          seriesBarDistance: 17,
          axisX: {
            offset: 20,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          plugins: [
            this.$chartist.plugins.legend({ legendNames: this.labelGraficoComparativo }),
            Tooltip({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + value + ' (mg/dl)' },
            }),
            this.linha({ value: 8.5, max: 8.5 }),
            this.linha({ value: 16, max: 16 }),
          ],
          chartPadding: {
            top: 30,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
      },
    },
  }
</script>

<style>

.grafico .ct-square {
  height: 290px;
}

.v-card--material-chart .v-card--material__heading {
  max-height: 295px !important;
}

.grafico .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

</style>

<style lang="sass">

.intervaloCbtCcs

 .ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut
  stroke: rgba(181, 229, 222,1)

 .ct-legend .ct-series-0:before
    background-color:  rgba(181, 229, 222, 1)
    border-color:  rgba(181, 229, 222, 1)

 .ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut
  stroke: rgba(34, 84, 255, 0.6)

 .ct-legend .ct-series-1:before
   background-color: rgba(34, 84, 255, 0.6)
   border-color: rgba(34, 84, 255, 0.6)

 .ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut
  stroke: rgba(255, 192, 0, 0.7)

 .ct-legend .ct-series-2:before
   background-color: rgba(255, 192, 0, 0.7)
   border-color: rgba(255, 192, 0, 0.7)

 .ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut
  stroke:  rgba(255, 0, 0, 0.5)

 .ct-legend .ct-series-3:before
   background-color:  rgba(255, 0, 0, 0.5)
   border-color:  rgba(255, 0, 0, 0.5)

.intervaloGordProt

  .ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut
    stroke:  rgba(255, 0, 0, 0.5)

  .ct-legend .ct-series-0:before
    background-color:  rgba(255, 0, 0, 0.5)
    border-color:  rgba(255, 0, 0, 0.5)

  .ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut
    stroke: rgba(255, 192, 0, 0.7)

  .ct-legend .ct-series-1:before
     background-color: rgba(255, 192, 0, 0.7)
     border-color: rgba(255, 192, 0, 0.7)

  .ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut
    stroke: rgba(34, 84, 255, 0.6)

  .ct-legend .ct-series-2:before
     background-color: rgba(34, 84, 255, 0.6)
     border-color: rgba(34, 84, 255, 0.6)

  .ct-series-a .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut
     stroke: rgba(181, 229, 222,1)

  .ct-legend .ct-series-3:before
       background-color:  rgba(181, 229, 222, 1)
       border-color:  rgba(181, 229, 222, 1)

.intervaloLactose

  .ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut
    stroke: rgba(255, 192, 0, 0.7)

  .ct-legend .ct-series-0:before
    background-color: rgba(255, 192, 0, 0.7)
    border-color: rgba(255, 192, 0, 0.7)

  .ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut
    stroke: rgba(181, 229, 222,1)

  .ct-legend .ct-series-1:before
      background-color:  rgba(181, 229, 222, 1)
      border-color:  rgba(181, 229, 222, 1)

</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="510px"
    width="510px"
    scrollable
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    style="height=662px !important; max-height=662px !important;"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span
            class="headline"
          >Re-cálculo das médias mensais e trimestrais</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        class="px-8 pt-0"
      >
        <v-row
          class="pt-2"
        >
          <v-col cols="4">
            <data-field
              v-model="data_inicial"
              :label="'Período Inicial'"
              :data-max="data_final"
              :mes-ano="true"
            />
          </v-col>
          <v-col cols="4">
            <data-field
              v-model="data_final"
              :label="'Período Final'"
              :data-min="data_inicial"
              :mes-ano="true"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="justify-end"
      >
        <v-btn
          color="error"
          rounded
          class="text-none text-white"
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          depressed
          rounded
          class="text-none text-white"
          @click="submit"
        >
          <v-icon
            dark
            left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          Calcular
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapState, mapMutations } from 'vuex'
  import { CLOSE } from '@/store/modules/qualidades'
  import moment from 'moment'
  import Swal from 'sweetalert2'
  import api from '@/api/api'

  export default {
    name: 'CalculoDialog',
    data () {
      return {
        data_inicial: 'from',
        data_final: 'to',

      }
    },
    computed: {
      ...mapState('qualidades', ['dialog']),
    },
    created () {
      let data_i = new Date()
      data_i.setMonth(data_i.getMonth() - 11)
      data_i = data_i.toISOString().slice(0, 10)
      const data_f = new Date().toISOString().slice(0, 10)
      this.data_inicial = moment(data_i).format('YYYY/MM')
      this.data_final = moment(data_f).format('YYYY/MM')
    },

    methods: {
      ...mapMutations('qualidades', [CLOSE]),

      submit () {
        const arrayData = []
        const date_inicio = new Date(this.data_inicial.substring(0, 4) + '/' + this.data_inicial.substring(5, 7) + '/01')
        const date_fim = new Date(this.data_final.substring(0, 4) + '/' + this.data_final.substring(5, 7) + '/01')

        date_inicio.setTime(date_inicio.getTime() + date_inicio.getTimezoneOffset() * 60 * 1000)
        date_fim.setTime(date_fim.getTime() + date_fim.getTimezoneOffset() * 60 * 1000)

        const diff = moment(date_fim).diff(moment(date_inicio))
        const meses = Math.round(moment.duration(diff).asMonths()) + 1

        let date_execution = date_inicio
        let duration = 0

        while (duration < meses) {
          arrayData.push(date_execution.toISOString())
          duration += 1
          date_execution = new Date(date_execution.setMonth(date_execution.getMonth() + 1))
        }

        try {
          api.cadastrarEntidade({ data_referencia: arrayData }, 'mediasqualidade/recalcula')
        } catch (error) {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Erro ao calcular',
            text: 'Erro ao calcular as médias mensais e trimestrais',
          })
        }

        this.CLOSE()
        return null
      },
      cancel () {
        this.CLOSE()
      },
    },

  }
</script>

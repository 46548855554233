<template>
  <div class="px-4 content">
    <v-tabs
      v-model="tab"
      center-active
      fixed-tabs
      icons-and-text
    >
      <v-tab>
        Análises da Qualidade
        <v-icon>
          mdi-flask-outline
        </v-icon>
      </v-tab>
      <v-tab
        @click="incrementTab"
      >
        Dashboard da Qualidade
        <v-icon>
          mdi-monitor-dashboard
        </v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <template>
          <base-loading
            v-if="!ready"
            base-class="fill-height ma-0 flex-column"
          />
          <div
            v-else
          >
            <crud-list
              v-model="qualidades"
              :headers="headers"
              :slots="[
                'item.data_referencia',
                'item.status',
                'item.data_analise',
                'item.valor_analise',
              ]"
              :permission="'list_qualidade'"
              :sort-by="['data_analise']"
              :sort-desc="[true]"
              styles-table="font-size: 10px; padding-top: 0px;  margin-top: 0px;"
              table-class="elevation-1 text-no-wrap"
              :item-height="$vuetify.breakpoint.lg ? 'calc(82vh - 263px)' : 'calc(81vh - 195px)'"
              striped
              export-pdf-xlsx
            >
              <template slot="datas">
                <v-col>
                  <data-field
                    v-model="data_inicial"
                    :label="'Data Inicial'"
                    :data-max="data_final"
                    @set="handleDataFilter()"
                  />
                </v-col>
                <v-col>
                  <data-field
                    v-model="data_final"
                    :label="'Data Final'"
                    :data-min="data_inicial"
                    @set="handleDataFilter()"
                  />
                </v-col>
              </template>
              <template
                v-if="hasPermission('inserir_qualidade')"
                v-slot:btnCadastro
              >
                <v-col>
                  <v-btn
                    v-if="!calculando"
                    block
                    color="primary"
                    rounded
                    class="text-none text-white"
                    @click="NEW_CALC"
                  >
                    <v-icon
                      dark
                      left
                    >
                      mdi-alarm-panel
                    </v-icon>
                    Re-calcular médias
                  </v-btn>
                  <v-btn
                    v-else
                    block
                    rounded
                    class="text-none text-white"
                    color="warning"
                  >
                    <v-icon
                      dark
                      left
                      class="mdi-spin"
                    >
                      mdi-clock-time-three
                    </v-icon>
                    Aguarde... Calculando médias
                  </v-btn>
                  <calculo-dialog />
                </v-col>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="statusColor(item.status)"
                  dark
                  outlined
                  small
                >
                  <v-icon
                    v-if="item.status == 1"
                    color="success"
                    small
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    v-if="item.status == 0"
                    small
                    color="error"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
              <template v-slot:[`item.data_referencia`]="{ item }">
                <span>{{ item.data_referencia.substring(5, 7) + '/' + item.data_referencia.substring(0, 4) }}</span>
              </template>
              <template v-slot:[`item.data_analise`]="{ item }">
                <span>{{ item.data_analise.substring(8, 10) + '/' + item.data_analise.substring(5, 7) + '/' + item.data_analise.substring(0, 4) }}</span>
              </template>
              <template v-slot:[`item.valor_analise`]="{ item }">
                <span>{{ parseFloat(item.valor_analise).toLocaleString('pt-BR') }}</span>
              </template>
            </crud-list>
          </div>
        </template>
      </v-tab-item>
      <v-tab-item>
        <template>
          <dashboard-qualidade :key="tabKey" />
        </template>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
  import QualidadesStore, { BOOTSTRAP, DELETE_ANALISE, NEW_CALC, CLOSE } from '@/store/modules/qualidades'
  import DashboardQualidade from './DashboardQualidade.vue'
  import CalculoDialog from './CalculoDialog.vue'
  import { sortDateTime } from '../../utils/sorts'
  import api from '@/api/api'

  export default {
    components: {
      CalculoDialog,
      DashboardQualidade,
    },
    data () {
      return {
        tab: 0,
        tabKey: 0,
        data_inicial: 'from',
        data_final: 'to',
        timeChecaCalculo: null,
        calculando: false,
        headers: [
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Período', value: 'data_referencia', width: 'auto', report: true, styleReport: { halign: 'center', type: 'mesAno' }, styleXLSX: { type: 'mesAno' } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Data da Análise', value: 'data_analise', width: 'auto', sort: sortDateTime, styleReport: { halign: 'center' }, styleXLSX: { type: 'date' }, report: true },
          { align: 'center', sortable: true, groupable: false, floatingfilter: true, text: 'Matrícula', value: 'cod_produtor_fornecedor', width: 'auto', report: true, styleReport: { halign: 'center' } },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Produtor', value: 'produtor', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Fazenda', value: 'fazenda', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Unidade de Negócio', value: 'unidade_negocio', width: 'auto', report: true },
          { align: 'center', sortable: true, groupable: false, floatingfilter: true, text: 'Código da Coleta', value: 'codigo_coleta', width: 'auto', report: true, styleReport: { halign: 'center' } },
          { align: 'center', sortable: true, groupable: true, floatingfilter: true, text: 'Sigla da Análise', value: 'sigla_analise', width: 'auto', report: true },
          {
            align: 'right',
            sortable: true,
            groupable: false,
            floatingfilter: false,
            text: 'Valor da Análise',
            value: 'valor_analise',
            width: 'auto',
            report: true,
            styleReport: { halign: 'right', type: 'number' },
            styleXLSX: { type: 'number' },
          },
          {
            align: 'center',
            sortable: false,
            groupable: false,
            floatingfilter: false,
            text: 'Válido',
            value: 'status',
            width: 'auto',
            report: true,
            styleXLSX: { type: 'alterValue' },
            alterValue: { 0: 'invalido', 1: 'valido' },
          },
        ],
      }
    },
    computed: {
      ...mapState('qualidades', ['ready', 'qualidades', 'dialog']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.hasModule('qualidades')) { this.$store.registerModule('qualidades', QualidadesStore) }

      const now = new Date()
      const data_i = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      const data_f = new Date(now.getFullYear(), now.getMonth() + 1, 0)

      this.data_inicial = data_i.toISOString().slice(0, 10)
      this.data_final = data_f.toISOString().slice(0, 10)

      this.BOOTSTRAP({ data_inicial: data_i.toISOString().slice(0, 10), data_final: data_f.toISOString().slice(0, 10) })
      this.$store.watch(('dialog', (val) => {
        val || this.CLOSE()
      }))
      this.checaCalculo()
      this.timeChecaCalculo = setInterval(() => { this.checaCalculo() }, 10000)
    },
    destroyed () {
      clearInterval(this.timeChecaCalculo)
    },
    methods: {
      ...mapActions('qualidades', [BOOTSTRAP, DELETE_ANALISE, NEW_CALC]),
      ...mapMutations('qualidades', [CLOSE]),
      handleDataFilter () {
        const data_i = this.data_inicial
        const data_f = this.data_final
        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
      },
      statusColor (status) {
        if (status === 0) return 'red'
        else return 'green'
      },
      async checaCalculo () {
        const status = await api.listEntidade('controlecalculo/checastatus/PR').then(p => p, () => [])
        this.calculando = status[0].length > 0
      },
      incrementTab () {
        this.tabKey = this.tabKey + 1
      },
    },
  }
</script>
